<template>
  <div class="detailBox">
    <navBar />
    <div class="box">
      <div class="safe">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="详情" name="detail"></el-tab-pane>
          <el-tab-pane label="提交作业" name="homeSubmit"></el-tab-pane>
        </el-tabs>
        <div style="margin-top: 20px">
          <router-view />
        </div>
      </div>
    </div>
    <navFooter />
  </div>
</template>
<script>
import navBar from "@/components/navBar/navBar.vue";
import navFooter from "@/components/navFooter/navFooter.vue";
export default {
  data() {
    return {
      activeName: this.$route.name,
    };
  },
  created() {
    console.log(this.$route.query.id);
  },
  methods: {
    handleClick(tab, event) {
      if (this.$route.name != tab.name) {
        this.$router.push({
          name: tab.name,
          query: { id: this.$route.query.id },
        });
      }
    },
  },
  components: { navBar, navFooter },
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.detailBox {
  min-height: 100vh;
  position: relative;
}
::v-deep .el-tabs__item {
  font-size: 22px;
  user-select: none;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
</style>
