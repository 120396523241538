<template>
  <div>
    <el-dialog
      title="我的积分"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <el-table :data="datas" stripe style="width: 100%">
        <el-table-column prop="integral_time" label="日期" align="center">
        </el-table-column>
        <el-table-column prop="integral" :label="integralAll" align="center">
        </el-table-column>
        <el-table-column prop="integral_type" label="积分类型" align="center">
        </el-table-column>
        <el-table-column prop="content" label="积分原因" align="center">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getIntegral } from "@/api/order.js";
export default {
  data() {
    return {
      dialogVisible: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      datas: [],
      integralAll: "积分",
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
      getIntegral({ id: this.userInfo.id }).then((res) => {
        this.datas = res.data;
        this.integralAll = `积分(合计:${res.integral_data})`
      });
    },
    yesBtn() {},
    handleClose() {
      this.dialogVisible = false;
      this.datas = [];
      this.integralAll = ""

    },
  },
};
</script>
<style lang="scss" scoped></style>
